import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import cardsData from "../../Data/cardsData";

const Card = ({data, swipe = false, index}) => {
	const [cardClass, setCardClass] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		if(swipe) {
			setTimeout(() => {
				setCardClass(true);
			}, 1000 * index);

		} else {
			setCardClass(false);
		}
	}, [swipe]);

	const converImageName = (el) => {
		// TODO: add names of images to the data and change using function
		const img_name = cardsData.filter(card => card.id === el.id)[0].card_name_eng.toLowerCase().replace(/\s+/g, '_');
		return `./images/cards/${img_name}.jpg`;
	}

	return (
		<div className="col-4 rotate-container">
			<div className={`card-front ${cardClass ? 'rotate-card-front' : 'rotate-card-back'}`}>
				<div className='cardImage'>
					<img src='./images/cards/card_default.jpg' alt='card taro'/>
				</div>
			</div>

			<div className="card_wrapper">
				<div className={`cardBlock card-back ${cardClass ? 'rotate-card-back' : 'rotate-card-front'}`} data-tooltip={t(`card_desc_${data.id}`)}>
					<div className='cardTitle'>
						<span>
							{t(`card_name_${data.id}`)}
						</span>
					</div>
					
					<div className='cardImage'>
						<img src={converImageName(data)} alt={data.card_name} />
					</div>
					
					<div className='cardConclusion'>
						{data.conclusion}
					</div>
				</div>	
			</div>
		</div>
	)
}

export default Card