// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang/en.json';
import ru from './lang/ru.json';
import ua from './lang/ua.json';

// Поддерживаемые языки
const availableLanguages = ['en', 'ru', 'ua'];

// Получаем язык из localStorage, если он там есть, иначе используем язык браузера или 'en'
const getDefaultLanguage = () => {
  	const savedLanguage = localStorage.getItem('language');
	if (savedLanguage && availableLanguages.includes(savedLanguage)) {
		return savedLanguage;

	} else {
		const browserLanguage = navigator.language.split('-')[0]; // берем язык браузера без региональных настроек
		return availableLanguages.includes(browserLanguage) ? browserLanguage : 'en';
	}
};

const defaultLanguage = getDefaultLanguage();

i18n
.use(initReactI18next)
.init({
    resources: {
		en: {
			translation: en
		},
		ru: {
			translation: ru
		},
		ua: {
			translation: ua
		}
    },
    lng: defaultLanguage, // используем язык по умолчанию
    fallbackLng: 'en',
    interpolation: {
		escapeValue: false
    }
});

export default i18n;
