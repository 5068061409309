
import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const ParticlesComponent = () => {
    const [ init, setInit ] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
		// CallBack after particles loading
        // console.log(container);
    };

    return (
		<div>

			{ init && <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={{
                fpsLimit: 120,
                interactivity: {
                    // events: {
                    //     onClick: {
                    //         enable: true,
                    //         mode: "push",
                    //     },
                    //     onHover: {
                    //         enable: true,
                    //         mode: "repulse",
                    //     },
                    //     resize: true,
                    // },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    "color": {
						"value": "#ffffff"
					},
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    "move": {
						"enable": true,
						"speed": 2,
						"direction": "none",
						"random": true,
						"straight": false,
						"out_mode": "out",
						"bounce": false,
						"attract": {
							"enable": false,
							"rotateX": 600,
							"rotateY": 1200
						}
					},
                    "number": {
						"value": 50,
						"density": {
							"enable": false,
							"value_area": 800
						}
					},
                    "opacity": {
						"value": 0.6,
						"random": true,
						"anim": {
							"enable": true,
							"speed": 0.16,
							"opacity_min": 0.1,
							"sync": false
						}
					},
                    "shape": {
						"type": "star",
						"stroke": {
							"width": 0,
							"color": "#000000"
						},
						"polygon": {
							"nb_sides": 5
						},
						"image": {
							"src": "img/github.svg",
							"width": 100,
							"height": 100
						}
					},
                    "size": {
						"value": 4,
						"random": true,
						"anim": {
							"enable": false,
							"speed": 40,
							"size_min": 0.1,
							"sync": false
						}
					},
					"line_linked": {
						"enable": true,
						"distance": 144.30708547789706,
						"color": "#ffffff",
						"opacity": 0.4,
						"width": 1
					},
                },
                detectRetina: true,
            }}
        />

			}
		</div>
	)
    ;
};

export default ParticlesComponent