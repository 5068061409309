const cardsData = [
	{ "id": 1, "card_name_eng": "The Fool", "card_name": "Дурак" },
	{ "id": 2, "card_name_eng": "The Magician", "card_name": "Маг" },
	{ "id": 3, "card_name_eng": "The High Priestess", "card_name": "Верховная Жрица" },
	{ "id": 4, "card_name_eng": "The Empress", "card_name": "Императрица" },
	{ "id": 5, "card_name_eng": "The Emperor", "card_name": "Император" },
	{ "id": 6, "card_name_eng": "The Hierophant", "card_name": "Иерофант" },
	{ "id": 7, "card_name_eng": "The Lovers", "card_name": "Влюбленные" },
	{ "id": 8, "card_name_eng": "The Chariot", "card_name": "Колесница" },
	{ "id": 9, "card_name_eng": "Strength", "card_name": "Сила" },
	{ "id": 10, "card_name_eng": "The Hermit", "card_name": "Отшельник" },
	{ "id": 11, "card_name_eng": "Wheel of Fortune", "card_name": "Колесо Фортуны" },
	{ "id": 12, "card_name_eng": "Justice", "card_name": "Правосудие" },
	{ "id": 13, "card_name_eng": "The Hanged Man", "card_name": "Повешенный" },
	{ "id": 14, "card_name_eng": "Death", "card_name": "Смерть" },
	{ "id": 15, "card_name_eng": "Temperance", "card_name": "Умеренность" },
	{ "id": 16, "card_name_eng": "The Devil", "card_name": "Дьявол" },
	{ "id": 17, "card_name_eng": "The Tower", "card_name": "Башня" },
	{ "id": 18, "card_name_eng": "The Star", "card_name": "Звезда" },
	{ "id": 19, "card_name_eng": "The Moon", "card_name": "Луна" },
	{ "id": 20, "card_name_eng": "The Sun", "card_name": "Солнце" },
	{ "id": 21, "card_name_eng": "Judgement", "card_name": "Суд" },
	{ "id": 22, "card_name_eng": "The World", "card_name": "Мир" },
	{ "id": 23, "card_name_eng": "Ace of Wands", "card_name": "Туз Жезлов" },
	{ "id": 24, "card_name_eng": "Two of Wands", "card_name": "Двойка Жезлов" },
	{ "id": 25, "card_name_eng": "Three of Wands", "card_name": "Тройка Жезлов" },
	{ "id": 26, "card_name_eng": "Four of Wands", "card_name": "Четверка Жезлов" },
	{ "id": 27, "card_name_eng": "Five of Wands", "card_name": "Пятерка Жезлов" },
	{ "id": 28, "card_name_eng": "Six of Wands", "card_name": "Шестерка Жезлов" },
	{ "id": 29, "card_name_eng": "Seven of Wands", "card_name": "Семерка Жезлов" },
	{ "id": 30, "card_name_eng": "Eight of Wands", "card_name": "Восьмерка Жезлов" },
	{ "id": 31, "card_name_eng": "Nine of Wands", "card_name": "Девятка Жезлов" },
	{ "id": 32, "card_name_eng": "Ten of Wands", "card_name": "Десятка Жезлов" },
	{ "id": 33, "card_name_eng": "Page of Wands", "card_name": "Паж Жезлов" },
	{ "id": 34, "card_name_eng": "Knight of Wands", "card_name": "Рыцарь Жезлов" },
	{ "id": 35, "card_name_eng": "Queen of Wands", "card_name": "Королева Жезлов" },
	{ "id": 36, "card_name_eng": "King of Wands", "card_name": "Король Жезлов" },
	{ "id": 37, "card_name_eng": "Ace of Cups", "card_name": "Туз Кубков" },
	{ "id": 38, "card_name_eng": "Two of Cups", "card_name": "Двойка Кубков" },
	{ "id": 39, "card_name_eng": "Three of Cups", "card_name": "Тройка Кубков" },
	{ "id": 40, "card_name_eng": "Four of Cups", "card_name": "Четверка Кубков" },
	{ "id": 41, "card_name_eng": "Five of Cups", "card_name": "Пятерка Кубков" },
	{ "id": 42, "card_name_eng": "Six of Cups", "card_name": "Шестерка Кубков" },
	{ "id": 43, "card_name_eng": "Seven of Cups", "card_name": "Семерка Кубков" },
	{ "id": 44, "card_name_eng": "Eight of Cups", "card_name": "Восьмерка Кубков" },
	{ "id": 45, "card_name_eng": "Nine of Cups", "card_name": "Девятка Кубков" },
	{ "id": 46, "card_name_eng": "Ten of Cups", "card_name": "Десятка Кубков" },
	{ "id": 47, "card_name_eng": "Page of Cups", "card_name": "Паж Кубков" },
	{ "id": 48, "card_name_eng": "Knight of Cups", "card_name": "Рыцарь Кубков" },
	{ "id": 49, "card_name_eng": "Queen of Cups", "card_name": "Королева Кубков" },
	{ "id": 50, "card_name_eng": "King of Cups", "card_name": "Король Кубков" },
	{ "id": 51, "card_name_eng": "Ace of Swords", "card_name": "Туз Мечей" },
	{ "id": 52, "card_name_eng": "Two of Swords", "card_name": "Двойка Мечей" },
	{ "id": 53, "card_name_eng": "Three of Swords", "card_name": "Тройка Мечей" },
	{ "id": 54, "card_name_eng": "Four of Swords", "card_name": "Четверка Мечей" },
	{ "id": 55, "card_name_eng": "Five of Swords", "card_name": "Пятерка Мечей" },
	{ "id": 56, "card_name_eng": "Six of Swords", "card_name": "Шестерка Мечей" },
	{ "id": 57, "card_name_eng": "Seven of Swords", "card_name": "Семерка Мечей" },
	{ "id": 58, "card_name_eng": "Eight of Swords", "card_name": "Восьмерка Мечей" },
	{ "id": 59, "card_name_eng": "Nine of Swords", "card_name": "Девятка Мечей" },
	{ "id": 60, "card_name_eng": "Ten of Swords", "card_name": "Десятка Мечей" },
	{ "id": 61, "card_name_eng": "Page of Swords", "card_name": "Паж Мечей" },
	{ "id": 62, "card_name_eng": "Knight of Swords", "card_name": "Рыцарь Мечей" },
	{ "id": 63, "card_name_eng": "Queen of Swords", "card_name": "Королева Мечей" },
	{ "id": 64, "card_name_eng": "King of Swords", "card_name": "Король Мечей" },
	{ "id": 65, "card_name_eng": "Ace of Pentacles", "card_name": "Туз Пентаклей" },
	{ "id": 66, "card_name_eng": "Two of Pentacles", "card_name": "Двойка Пентаклей" },
	{ "id": 67, "card_name_eng": "Three of Pentacles", "card_name": "Тройка Пентаклей" },
	{ "id": 68, "card_name_eng": "Four of Pentacles", "card_name": "Четверка Пентаклей" },
	{ "id": 69, "card_name_eng": "Five of Pentacles", "card_name": "Пятерка Пентаклей" },
	{ "id": 70, "card_name_eng": "Six of Pentacles", "card_name": "Шестерка Пентаклей" },
	{ "id": 71, "card_name_eng": "Seven of Pentacles", "card_name": "Семерка Пентаклей" },
	{ "id": 72, "card_name_eng": "Eight of Pentacles", "card_name": "Восьмерка Пентаклей" },
	{ "id": 73, "card_name_eng": "Nine of Pentacles", "card_name": "Девятка Пентаклей" },
	{ "id": 74, "card_name_eng": "Ten of Pentacles", "card_name": "Десятка Пентаклей" },
	{ "id": 75, "card_name_eng": "Page of Pentacles", "card_name": "Паж Пентаклей" },
	{ "id": 76, "card_name_eng": "Knight of Pentacles", "card_name": "Рыцарь Пентаклей" },
	{ "id": 77, "card_name_eng": "Queen of Pentacles", "card_name": "Королева Пентаклей" },
	{ "id": 78, "card_name_eng": "King of Pentacles", "card_name": "Король Пентаклей" }
];

export default cardsData;