import "./Separator.scss";

const Separator = () => {
	return (
		<div className="Separator">
			<div className="Seperator_hr"></div>
			<img className="Seperator_img" src="./images/separator.svg" alt="Separator" />
			<div className="Seperator_hr"></div>
		</div>
	)
}

export default Separator;