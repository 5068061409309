import Separator from "../Separator/Separator";
import ArticlePullQuote from "../svgIcons/ArticlePullQuote";
import Card from "./Card";
import "./CardList.scss";

const CardList = (props) => {
	const {data, isOpen, conslusion} = props;

	return (
		<div className="popupCards">
			<div className='col-12 popupConclusion'>
				<ArticlePullQuote />
					{conslusion.general_conclusion}
				<Separator />
			</div>

			<div className='row popupCardsList'>
				{data.length ?			
					data.map((el, index)=> (<Card data={el} swipe={isOpen} index={index} key={`card-${index}`}/>))
				: 
					'No data'
				}
			</div>

			
		</div>
	)
}

export default CardList;