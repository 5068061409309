import './App.css';
import './Fonts.css';
import './bootstrap-5.3.3-dist/css/bootstrap-grid.min.css';
import './bootstrap-5.3.3-dist/css/bootstrap-grid.min.css';
import TarotApp from './components/Taro';
import TaroBg from './components/TaroBg/TaroBg';

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-DPD4NWNZ7R"; // Замените на ваш идентификатор измерения GA4


function App() {
	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
		ReactGA.send("pageview"); // Отправка события "Просмотр страницы"
	}, []);

	return (
		<div className="App">
			<TaroBg />
			<TarotApp />
		</div>
	);
}

export default App;


