import LightRays from "../LightRays/LightRays";
import ParticlesComponent from "../Particles/Particles";
import "./TaroBg.scss";

const TaroBg = () => {

	return (
		<div className='TaroBg'>
			<ParticlesComponent />
			<div className='TaroBg-left-column' style={{background: "url('./images/TaroBg/TaroBg-left-column.png') repeat-y"}}></div>
			<div className='TaroBg-left-top' style={{background: "url('./images/TaroBg/TaroBg-left-top.png') no-repeat center"}}></div>
			<div className='TaroBg-left-bottom' style={{background: "url('./images/TaroBg/TaroBg-left-bottom.png') no-repeat center"}}></div>

			<div className='TaroBg-right-column' style={{background: "url('./images/TaroBg/TaroBg-right-column.png') repeat-y"}}></div>
			<div className='TaroBg-right-top' style={{background: "url('./images/TaroBg/TaroBg-right-top.png') no-repeat center"}}></div>
			<div className='TaroBg-right-bottom' style={{background: "url('./images/TaroBg/TaroBg-right-bottom.png') no-repeat center"}}></div>
			<div className='TaroBg-top' style={{background: "url('./images/TaroBg/TaroBg-top.png') repeat-x"}}></div>

			<div className='TaroBg-flying-bird' style={{background: "url('./images/TaroBg/flying-bird.png') no-repeat center center"}}></div>

			
			<LightRays />
		</div>
	)
}

export default TaroBg;